<template>
  <div>
    <w-top-bar>Pedidos</w-top-bar>
    <!-- <pre class="text-xs">{{ results }}</pre> -->
    <div class="h-full p-5 bg-gray-200">
      <div class="flex items-center justify-between h-12 px-1 print:hidden">
        <div class="flex items-center text-xs">
          <w-select
            label="Mostrar"
            class="z-20 md:w-48"
            v-model="filter"
            :options="orderTypes"
          />
        </div>
        <!-- <button
          @click="print()"
          class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-800 bg-gray-400 rounded print:hidden hover:bg-gray-500"
        >
          <svg
            class="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
          <span>Imprimir</span>
        </button>
        <button
          @click="download()"
          class="inline-flex items-center px-4 py-2 ml-2 text-sm font-medium text-gray-800 bg-gray-400 rounded print:hidden hover:bg-gray-500"
        >
          <svg  class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
</svg>
          <span>Descargar CSV</span>
        </button> -->
        <!-- <router-link
          to="/food-orders"
          class="flex items-center text-sm text-blue-500 cursor-pointer hover:text-blue-600"
        >
          Ver Pedidos Comida
        </router-link> -->
        <a
          @click="clear()"
          class="
            flex
            items-center
            text-sm text-blue-500
            cursor-pointer
            hover:text-blue-600
          "
        >
          Limpiar filtros
        </a>
      </div>
      <div>
        <div class="relative pt-2 mx-auto mb-2 text-gray-600">
          <input
            class="
              w-full
              h-10
              px-5
              pr-16
              text-sm
              bg-white
              border-2 border-gray-300
              rounded-lg
              focus:outline-none
            "
            type="search"
            v-model="search"
            :debounce="1000"
            name="search"
            placeholder="Buscar por nombre, apellido, productos, correo electrónico o ID de Pedido"
          />
          <button
            @click="doSearch()"
            type="submit"
            class="absolute top-0 right-0 mt-5 mr-4"
          >
            <svg
              class="w-4 h-4 text-gray-600 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </div>
      <w-loading v-if="busy && results.length === 0"></w-loading>
      <w-orders
        :busy="busy"
        v-else
        @reload="reload()"
        @next="more()"
        :items="results"
      />
      <!-- <button @click="more()">Load More</button> -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";
// import { makeFindMixin } from "feathers-vuex";
import { mapActions, mapGetters, mapMutations } from "vuex";
const apiURL =
  process.env.NODE_ENV === "production"
    ? "https://api.orsai.org/"
    : "http://localhost:3030/";

export default {
  name: "ordersList",
  data() {
    return {
      busy: false,
      page: 1,
      skip: 0,
      limit: 2,
      searchTerm: null,
      filter: "approved",
      orderTypes: [
        { label: "Aprobados", key: "approved", query: { status: "paid" } },
        { label: "Pendientes", key: "pending", query: { status: null } },
        {
          label: "Rechazados",
          key: "cancelled",
          query: { status: "cancelled" },
        },
        {
          label: "Devoluciones",
          key: "refunded",
          query: { status: "refunded" },
        },
        { label: "Archivados", key: "archived", query: { archive: true } },
      ],
    };
  },
  // mixins: [makeFindMixin({ service: "orders", watch: true })],
  async mounted() {
    if (this.$route.query.filter) this.filter = this.$route.query.filter;
    if (this.$route.query.search) this.searchTerm = this.$route.query.search;

    this.reload();
    // let params = { query: {} };
    // console.log(models);
    // let rs = await models.api.Orders.find(params);
    // console.log("walter 1", rs);
  },
  watch: {
    search() {
      this.reload();
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("orders", { findResultsInStore: "find" }),
    results() {
      return this.findResultsInStore({ query: { ...this.query } }).data;
    },
    search: {
      get() {
        return this.searchTerm;
      },
      set: _.debounce(function (val) {
        if (this.searchTerm !== val) this.searchTerm = val;
      }, 1000),
    },
    query() {
      if (this.search && this.search.length > 0) return {};
      if (!this.filter) return this.orderTypes[0].query;
      return this.orderTypes.find((el) => el.key === this.filter).query;
    },
    orderTypeParams() {
      if (!this.filter) return this.orderTypes[0].query;
      return this.orderTypes.find((el) => el.key === this.filter).query;
    },
    ordersParams() {
      let or = {},
        skus = {};
      // eslint-disable-next-line no-constant-condition
      if (this.search) {
        // eslint-disable-next-line no-unused-vars
        or = {
          $or: [
            // {
            //   "shipping.name": {
            //     $regex: this.search,
            //     $options: "igm",
            //   },
            // },
            // {
            //   "shipping.lastName": {
            //     $regex: this.search,
            //     $options: "igm",
            //   },
            // },
            // {
            //   "shipping.email": {
            //     $regex: this.search,
            //     $options: "igm",
            //   },
            // },
            // {
            //   orderId: {
            //     $regex: this.search,
            //     $options: "igm",
            //   },
            // },
          ],
        };
      }

      if (this.searchSku) {
        // eslint-disable-next-line no-unused-vars
        skus = {
          "cart.sku": {
            $in: [
              {
                $regex: this.search,
                $options: "igm",
              },
            ],
          },
        };
      }

      return {
        query: {
          // archive: { $ne: true },
          // ...this.query,
          // ...skus,
          // ...or,
          $sort: { createdAt: -1 },
          // $skip: (this.page - 1) * this.limit,
          $limit: this.limit * this.page,
        },
        debounce: 1000,
      };
    },
    // ordersQid() {
    //   return "search-" + this.searchTerm;
    // },
    ordersFetchParams() {
      return {
        query: {
          ...this.orderTypeParams,
          $search: this.search,
          $sort: { createdAt: -1 },
          $limit: this.limit,
        },
        debounce: 1000,
      };
    },
    //   console.log("ordersFetchParams");
    //   if (this.searchTerm) {
    //     return {
    //       query: {
    //         search: this.searchTerm,
    //       },
    //     };
    //   }
    //   return {
    //     query: {
    //       // archive: { $ne: true },
    //       $sort: { createdAt: -1 },
    //     },
    //     $limit: 10,
    //   };
    // },
  },
  created() {
    console.log(this.user);
    if (!this.user.isAdmin) this.$router.push("/food-orders");
  },
  methods: {
    ...mapActions("orders", { findResults: "find" }),
    ...mapMutations("orders", {
      clearAll: "clearAll",
    }),
    clear() {
      this.filter = "approved";
      this.searchTerm = "";
      this.clearAll();
      this.reload();
    },
    more() {
      console.log("More");
      this.page++;
      this.findResults(this.ordersFetchParams);
    },
    doSearch() {
      this.searchTerm = this.search;
      this.reload();
    },
    async reload() {
      console.log("Save", this.searchTerm);
      console.log("Status", this.filter);
      this.$router.push({
        path: "orders",
        query: { filter: this.filter, search: this.search },
      });
      this.busy = true;
      await this.clearAll();
      console.log("reload", this.ordersFetchParams);
      await this.findResults({
        query: {
          $search: this.search,
          $sort: { createdAt: -1 },
          $limit: this.limit * this.page,
        },
      });
      this.busy = false;
    },
    print() {
      window.print();
    },
    async download() {
      console.log("download");
      let fileName = "pedidos-date-";
      this.downloading = true;
      this.axios
        .get(`${apiURL}orders`, {
          responseType: "blob",
          params: {
            $limit: -1,
            ...this.query,
            // $sort: {createdAt: -1}
          },
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `inscripciones-${fileName}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
